<script>
  import AuthorInfo from "$lib/components/atoms/AuthorInfo.svelte";
</script>

<section class="py-6 px-4">
  <AuthorInfo author="jeremy-jousse" size="m" />

  <div class="py-4 space-y-2">
    <h2 class="text-4xl font-bold">Bonjour! Je suis Jérémy Jousse.</h2>
    <p class="text-2xl font-light">Je suis full stack software engineer.</p>
  </div>
</section>
